import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import {
  filter,
  map,
  find,
  concat,
  without,
  includes,
  get,
  omit
} from "lodash";
import Fuse from "fuse.js";
import { formValueSelector } from "redux-form";

import {
  TextField as TextInput,
  Toggle as ToggleInput,
  SelectField as SelectInput,
  RadioButtonGroup
} from "redux-form-material-ui";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import { RadioButton } from "material-ui/RadioButton";
import { List, ListItem } from "material-ui/List";
import Paper from "material-ui/Paper";
import TextField from "material-ui/TextField";
import AddIcon from "material-ui/svg-icons/content/add";
import RemoveIcon from "material-ui/svg-icons/content/remove";
import MenuItem from "material-ui/MenuItem";

import {
  required,
  maxLength50,
  email,
  boolRequired
} from "../../helpers/validation";
import { theme } from "../../app/App";
import {
  createAdminUser as createAdminUserAction,
  editAdminUser as editAdminUserAction
} from "../adminUserActions";
import {
  PARTNER_ADMIN,
  EMPLOYERS_ADMIN,
  EMPLOYER_MANAGER,
  SUPPORT_REP,
  PARTNER_MANAGER,
  BENEFITS_ADMIN,
  SYSTEM_ADMIN,
  SELF_SERVICE_ADMIN
} from "../../constants";
import { selectEntityIdentifiers } from "../../partners/selectors";

const styles = {
  inputUnderline: { borderColor: "#258ffa" },
  inputLabel: { color: "#00acc1" },
  raisedButton: { borderRadius: "3px" },
  width70: { width: "70%" },
  marginRightLeft15: { margin: "0 15% 0 15%" },
  formButtonsBar: { marginTop: "20px", float: "right" },
  activeToggle: { width: "30%", fontWeight: "bold", margin: "20px 0 0 15%" },
  buttonIcon: { height: "20px" },
  error: { color: "#FF0000" },
  employerOptionsContainer: {
    display: "flex",
    alignItems: "columns",
    justifyContent: "space-between"
  },
  search: {
    alignSelf: "flex-end",
    flexBasis: "25%"
  },
  flexBasis45: { flexBasis: "45%" },
  employerSelectContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px"
  },
  employerList: {
    height: "250px",
    overflow: "auto"
  },
  listItem: {
    flexBasis: "85%",
    flexShrink: 0,
    flexGrow: 0,
    alignSelf: "center",
    fontSize: "14px"
  },
  listIcon: {
    flexBasis: "15%",
    flexShrink: 0,
    flexGrow: 0,
    alignSelf: "center"
  },
  icon: { height: "20px", width: "20px" },
  helperText: {
    color: "gray",
    fontSize: "10px"
  }
};

const roleOptions = [
  { value: PARTNER_ADMIN, primaryText: "Partner Admin" },
  { value: PARTNER_MANAGER, primaryText: "Partner Manager" },
  { value: EMPLOYERS_ADMIN, primaryText: "Employer Admin" },
  { value: EMPLOYER_MANAGER, primaryText: "Employer Manager" },
  { value: SUPPORT_REP, primaryText: "Support Representative" },
  { value: BENEFITS_ADMIN, primaryText: "Benefits Admin" },
  { value: SYSTEM_ADMIN, primaryText: "System Admin" },
  { value: SELF_SERVICE_ADMIN, primaryText: "Self Service Admin" }
];

const renderRoleOptions = ({ currentUserRole, context }) => {
  const options = [];

  //Allow SYSTEM_ADMIN to create other SYSTEM_ADMIN
  if (currentUserRole === SYSTEM_ADMIN && context === SYSTEM_ADMIN) {
    options.push(<MenuItem {...roleOptions[6]} />);
    return options;
  }

  if (
    ![PARTNER_ADMIN, EMPLOYERS_ADMIN, EMPLOYER_MANAGER].includes(
      currentUserRole
    )
  )
    options.push(<MenuItem {...roleOptions[0]} />);
  if (
    ![PARTNER_ADMIN, EMPLOYERS_ADMIN, EMPLOYER_MANAGER].includes(
      currentUserRole
    )
  )
    options.push(<MenuItem {...roleOptions[1]} />);
  if (
    ![PARTNER_ADMIN, EMPLOYERS_ADMIN, EMPLOYER_MANAGER].includes(
      currentUserRole
    )
  )
  options.push(<MenuItem {...roleOptions[2]} />);
  options.push(<MenuItem {...roleOptions[3]} />);
  options.push(<MenuItem {...roleOptions[4]} />);
  options.push(<MenuItem {...roleOptions[5]} />);

  return options;
};

class ValidatedRadioButtonGroup extends RadioButtonGroup {
  render() {
    const {
      helperText,
      meta: { touched, error }
    } = this.props;
    return (
      <div>
        {super.render()}
        {helperText && <span style={styles.helperText}>{helperText}</span>}
        {touched && error && <span style={styles.error}>{error}</span>}
      </div>
    );
  }
}
export class AdminUserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEmployers: props.selectedEmployers,
      searchTerm: ""
    };
  }

  componentDidUpdate(prevProps) {
    const { form, selectedType, changeFormValue } = this.props;
    if (
      prevProps.selectedType !== selectedType &&
      selectedType === BENEFITS_ADMIN
    ) {
      changeFormValue(form, "whitelist", true);
    }
  }

  _generateRemainingEmployers() {
    return filter(
      this.props.employers,
      emp =>
        !find(this.state.selectedEmployers, { employer_id: emp.employer_id })
    );
  }

  _handleChange(val) {
    this.setState({ selectedEmployers: val });
  }

  _removeEmployer = emp => {
    this.setState({
      selectedEmployers: filter(
        this.state.selectedEmployers,
        val => val.employer_id !== emp.employer_id
      )
    });
  };

  _addEmployer = emp => {
    this.setState({
      selectedEmployers: concat(this.state.selectedEmployers, emp)
    });
  };

  _search = e => {
    this.setState({ searchTerm: e.target.value });
  };

  _submit = values => {
    const { currentUserRole, form, employers, onSuccess } = this.props;
    const currentSelectedEmployers = this.state.selectedEmployers;

    if (form === "adminUserCreate") {
      let createEmployers;
      if ([EMPLOYERS_ADMIN, EMPLOYER_MANAGER].includes(currentUserRole)) {
        createEmployers = employers;
      } else {
        createEmployers = map(currentSelectedEmployers, emp => emp.employer_id);
      }

      let createValues = values;
      if (values.type === PARTNER_ADMIN) {
        createEmployers = [];
        createValues = omit(values, "whitelist");
      }

      this.props.createAdminUser(
        createValues,
        this.props.form,
        values.type,
        this.props.partnerID,
        createEmployers
      );
    }

    if (form === "adminUserEdit") {
      const prevSelectedEmployers = this.props.selectedEmployers;
      // New employers
      const employersToAdd = filter(
        currentSelectedEmployers,
        emp =>
          !includes(
            map(prevSelectedEmployers, val => val.employer_id),
            emp.employer_id
          )
      );

      // Employers to remove
      const employersToRemove = filter(
        prevSelectedEmployers,
        emp => !includes(map(currentSelectedEmployers, val => val.id), emp.id)
      );

      // Current employers (Must pass in to update whitelist)
      const currentEmployers = without(
        prevSelectedEmployers,
        ...employersToRemove
      );

      this.props.editAdminUser(
        values,
        this.props.form,
        this.props.adminUserID,
        values.type,
        employersToAdd,
        employersToRemove,
        currentEmployers,
        onSuccess
      );
    }
  };

  isPartnerLevelAdmin = role =>
    includes([PARTNER_ADMIN, PARTNER_MANAGER], role);

  render() {
    const {
      handleSubmit,
      handlePrev,
      backButtonLabel,
      submitButtonLabel,
      emailDisabled,
      currentUserID,
      currentUserRole,
      initialValues,
      context,
      selectedType,
      entityIdentifiers
    } = this.props;

    var fuseOptions = {
      shouldSort: true,
      threshold: 0.6,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ["name"]
    };
    const { selectedEmployers, searchTerm } = this.state;
    const matchesCurrentUser = get(initialValues, "id") === currentUserID;
    const remainingEmployers = this._generateRemainingEmployers();

    const remainingEmployersResult =
      searchTerm.length > 0
        ? new Fuse(remainingEmployers, fuseOptions).search(searchTerm)
        : remainingEmployers;

    const selectedEmployersResult =
      searchTerm.length > 0
        ? new Fuse(selectedEmployers, fuseOptions).search(searchTerm)
        : selectedEmployers;
    const primaryColor = theme.palette["primary1Color"];

    const isSelectedTypePartnerLevel = this.isPartnerLevelAdmin(selectedType);

    const showEmployerOptions =
      !isSelectedTypePartnerLevel &&
      selectedType !== undefined &&
      context === PARTNER_ADMIN;

    const whitelistHelperText =
      selectedType === BENEFITS_ADMIN
        ? "Benefit Admin Users can only be assigned to a single Employer"
        : null;
    const disableWhitelist = selectedType === BENEFITS_ADMIN;

    const submitDisabled =
      context === PARTNER_ADMIN &&
      !isSelectedTypePartnerLevel &&
      selectedEmployers.length === 0;

    const showRoles = [
      SYSTEM_ADMIN,
      PARTNER_ADMIN,
      PARTNER_MANAGER,
      EMPLOYER_MANAGER,
      EMPLOYERS_ADMIN
    ].includes(currentUserRole);

    return (
      <div>
        <form
          onSubmit={
            context === PARTNER_ADMIN
              ? handleSubmit(this._submit)
              : handleSubmit
          }
        >
          <div>
            <Field
              name="email"
              component={TextInput}
              floatingLabelText={emailDisabled ? "Email" : "Email *"}
              floatingLabelShrinkStyle={
                emailDisabled ? null : styles.inputLabel
              }
              underlineStyle={styles.inputUnderline}
              style={{ ...styles.width70, ...styles.marginRightLeft15 }}
              validate={[required, email]}
              disabled={emailDisabled}
              autoFocus={true}
            />
          </div>
          <div>
            <Field
              name="first_name"
              component={TextInput}
              floatingLabelText="First Name *"
              floatingLabelShrinkStyle={styles.inputLabel}
              underlineStyle={styles.inputUnderline}
              style={{ ...styles.width70, ...styles.marginRightLeft15 }}
              validate={[required, maxLength50]}
            />
          </div>
          <div>
            <Field
              name="last_name"
              component={TextInput}
              floatingLabelText="Last Name *"
              floatingLabelShrinkStyle={styles.inputLabel}
              underlineStyle={styles.inputUnderline}
              style={{ ...styles.width70, ...styles.marginRightLeft15 }}
              validate={[required, maxLength50]}
            />
          </div>
          <div>
            <Field
              name="active"
              component={ToggleInput}
              label="Active"
              labelStyle={{ fontSize: "1.25em" }}
              style={styles.activeToggle}
              normalize={val => (val ? true : false)}
              disabled={matchesCurrentUser}
            />
          </div>
          {showRoles && (
            <div>
              <div>
                <Field
                  name="type"
                  component={SelectInput}
                  floatingLabelText="Role"
                  autoWidth={true}
                  style={{ ...styles.marginRightLeft15 }}
                  disabled={matchesCurrentUser}
                >
                  {renderRoleOptions({ currentUserRole, context })}
                </Field>
              </div>
              {showEmployerOptions && (
                <div>
                  <div style={styles.employerOptionsContainer}>
                    <div style={styles.search}>
                      <TextField
                        hintText="Search"
                        style={{ width: "100%" }}
                        onChange={e => this._search(e)}
                      />
                    </div>
                    <div style={styles.flexBasis45}>
                      <Field
                        name="whitelist"
                        helperText={whitelistHelperText}
                        component={ValidatedRadioButtonGroup}
                        normalize={val => {
                          return val === "whitelist";
                        }}
                        format={val => {
                          if (typeof val !== "boolean") return;
                          return val ? "whitelist" : "blacklist";
                        }}
                        validate={[boolRequired]}
                      >
                        <RadioButton
                          disabled={disableWhitelist}
                          value="blacklist"
                          label="Allow Access to All, Except:"
                        />
                        <RadioButton
                          disabled={disableWhitelist}
                          value="whitelist"
                          label="Only Allow Access to:"
                        />
                      </Field>
                    </div>
                  </div>
                  <div style={styles.employerSelectContainer}>
                    <div
                      style={{ ...styles.flexBasis45, alignItems: "columns" }}
                    >
                      <Paper>
                        <List style={styles.employerList}>
                          {remainingEmployersResult.length === 0 ? (
                            <ListItem disabled={true}>
                              {`No ${entityIdentifiers.employerIdentifier}s Remaining`}
                            </ListItem>
                          ) : (
                            remainingEmployersResult.map(emp => (
                              <ListItem
                                onClick={() => this._addEmployer(emp)}
                                key={emp.employer_id}
                                style={{ minHeight: "40px" }}
                              >
                                <div style={{ display: "flex" }}>
                                  <div style={styles.listItem}>{emp.name}</div>
                                  <div style={styles.listIcon}>
                                    <AddIcon
                                      color={primaryColor}
                                      style={styles.icon}
                                    />
                                  </div>
                                </div>
                              </ListItem>
                            ))
                          )}
                        </List>
                      </Paper>
                    </div>
                    <div style={styles.flexBasis45}>
                      <Paper>
                        <List style={styles.employerList}>
                          {selectedEmployers.length === 0 ? (
                            <ListItem disabled={true} style={styles.error}>
                              Must select at least one employer
                            </ListItem>
                          ) : (
                            selectedEmployersResult.map(emp => (
                              <ListItem
                                key={emp.employer_id}
                                onClick={() => this._removeEmployer(emp)}
                              >
                                <div style={{ display: "flex" }}>
                                  <div style={styles.listItem}>{emp.name}</div>
                                  <div style={styles.listIcon}>
                                    <RemoveIcon
                                      color="#FF0000"
                                      style={styles.icon}
                                    />
                                  </div>
                                </div>
                              </ListItem>
                            ))
                          )}
                        </List>
                      </Paper>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          <div style={styles.formButtonsBar}>
            {handlePrev ? (
              <FlatButton
                label={backButtonLabel}
                type="button"
                onClick={handlePrev}
                style={{ marginRight: "12px" }}
              />
            ) : null}
            <RaisedButton
              label={submitButtonLabel}
              type="submit"
              buttonStyle={styles.raisedButton}
              style={styles.raisedButton}
              primary={true}
              disabled={submitDisabled}
            />
          </div>
        </form>
      </div>
    );
  }
}

AdminUserForm.propTypes = {
  formName: PropTypes.string.isRequired,
  handlePrev: PropTypes.func,
  backButtonLabel: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  emailDisabled: PropTypes.bool,
  selectedEmployers: PropTypes.array.isRequired,
  employers: PropTypes.array
};

AdminUserForm.defaultProps = {
  backButtonLabel: "Cancel",
  submitButtonLabel: "Save",
  emailDisabled: false,
  selectedEmployers: []
};

function mapStateToProps(state, props) {
  const formName = props.formName;
  const typeSelector = formValueSelector(props.formName);
  const entityIdentifiers = selectEntityIdentifiers(state);
  const selectedType = typeSelector(state, "type");
  return {
    form: formName,
    currentUserID: state.userContext.userID,
    currentUserRole: state.userContext.userRole,
    selectedType,
    entityIdentifiers
  };
}

export default connect(
  mapStateToProps,
  {
    createAdminUser: createAdminUserAction,
    editAdminUser: editAdminUserAction,
    changeFormValue: change
  }
)(
  reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })(AdminUserForm)
);
