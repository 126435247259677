import { DOWNLOAD } from "../rest/types";

export const DOWNLOAD_PARTNER_REPORT = "DOWNLOAD_PARTNER_REPORT";
export const DOWNLOAD_PARTNER_REPORT_FAILURE =
  "DOWNLOAD_PARTNER_REPORT_FAILURE";
export const DOWNLOAD_PARTNER_REPORT_SUCCESS =
  "DOWNLOAD_PARTNER_REPORT_SUCCESS";

export const downloadPartnerReport = (id, basePath) => ({
  type: DOWNLOAD_PARTNER_REPORT,
  payload: { id },
  meta: { resource: "partners", fetch: DOWNLOAD, cancelPrevious: false }
});

export const FETCH_PARTNER_STATS = "FETCH_PARTNER_STATS";
export const FETCH_PARTNER_STATS_FAILURE = "FETCH_PARTNER_STATS_FAILURE";
export const FETCH_PARTNER_STATS_SUCCESS = "FETCH_PARTNER_STATS_SUCCESS";

export const fetchPartnerStats = partnerID => ({
  type: FETCH_PARTNER_STATS,
  payload: { partnerID }
});

export const FETCH_PARTNER = "FETCH_PARTNER";
export const FETCH_PARTNER_FAILURE = "FETCH_PARTNER_FAILURE";
export const FETCH_PARTNER_SUCCESS = "FETCH_PARTNER_SUCCESS";

export const fetchPartner = partnerID => ({
  type: FETCH_PARTNER,
  payload: { partnerID }
});

export const CREATE_PARTNER = "CREATE_PARTNER";
export const CREATE_PARTNER_FAILURE = "CREATE_PARTNER_FAILURE";
export const CREATE_PARTNER_SUCCESS = "CREATE_PARTNER_SUCCESS";

export const createPartner = (data, formName) => ({
  type: CREATE_PARTNER,
  payload: { data },
  formName
});

export const FETCH_PARTNER_ADMIN_USERS = "FETCH_PARTNER_ADMIN_USERS";
export const FETCH_PARTNER_ADMIN_USERS_FAILURE =
  "FETCH_PARTNER_ADMIN_USERS_FAILURE";
export const FETCH_PARTNER_ADMIN_USERS_SUCCESS =
  "FETCH_PARTNER_ADMIN_USERS_SUCCESS";

export const fetchPartnerAdminUsers = (sort, page) => ({
  type: FETCH_PARTNER_ADMIN_USERS,
  payload: { sort, page }
});

export const EDIT_PARTNER = "EDIT_PARTNER";
export const EDIT_PARTNER_FAILURE = "EDIT_PARTNER_FAILURE";
export const EDIT_PARTNER_SUCCESS = "EDIT_PARTNER_SUCCESS";

export const editPartner = (data, formName) => ({
  type: EDIT_PARTNER,
  payload: { data },
  formName
});

export const TOGGLE_PARTNER_REFI_VENDOR = "TOGGLE_PARTNER_REFI_VENDOR";
export const TOGGLE_PARTNER_REFI_VENDOR_FAILURE =
  "TOGGLE_PARTNER_REFI_VENDOR_FAILURE";
export const TOGGLE_PARTNER_REFI_VENDOR_SUCCESS =
  "TOGGLE_PARTNER_REFI_VENDOR_SUCCESS";

export const togglePartnerRefiVendor = (
  partnerID,
  refiVendorID,
  partnerRefiVendorID,
  toggled = true
) => ({
  type: TOGGLE_PARTNER_REFI_VENDOR,
  payload: { partnerID, refiVendorID, partnerRefiVendorID, toggled }
});

export const FETCH_PARTNERS = "FETCH_PARTNERS";
export const FETCH_PARTNERS_FAILURE = "FETCH_PARTNERS_FAILURE";
export const FETCH_PARTNERS_SUCCESS = "FETCH_PARTNERS_SUCCESS";

export const fetchPartners = defaultPartnerID => ({
  type: FETCH_PARTNERS,
  payload: { defaultPartnerID }
});

export const SET_SELECTED_PARTNER = "SET_SELECTED_PARTNER";
export const setSelectedPartner = partnerID => ({
  type: SET_SELECTED_PARTNER,
  payload: { partnerID }
});

export const FETCH_PARTNER_REG_CLICK = "FETCH_PARTNER_REG_CLICK";
export const FETCH_PARTNER_REG_CLICK_SUCCESS = "FETCH_PARTNER_REG_CLICK_SUCCESS";
export const FETCH_PARTNER_REG_CLICK_FAILURE = "FETCH_PARTNER_REG_CLICK_FAILURE";

export const fetchPartnerRegClick = (partnerID) => ({
  type: FETCH_PARTNER_REG_CLICK,
  payload: { partnerID},
});

export const FETCH_PARTNERS_DETAILS= "FETCH_PARTNERS_DETAILS";
export const FETCH_PARTNERS_DETAILS_SUCCESS = "FETCH_PARTNERS_DETAILS_SUCCESS";
export const FETCH_PARTNERS_DETAILS_FAILURE = "FETCH_PARTNERS_DETAILS_FAILURE";

export const fetchPartnersDetails = (partnerID) => (
  {
  type: FETCH_PARTNERS_DETAILS,
  payload: { partnerID },
});

