import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import styled from "styled-components";
import moment from "moment";
import muiThemeable from "material-ui/styles/muiThemeable";

import reject from "lodash/reject";
import filter from "lodash/filter";
import round from "lodash/round";
import toNumber from "lodash/toNumber";
import isFinite from "lodash/isFinite";
import { formatCurrency } from "helpers/formattingHelpers";
import { fetchRegisteredClick as fetchRegisteredClickAction, fetchEmployersDetails as fetchEmployersDetailsAction } from "./employerActions";

import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  Hint
} from "react-vis";
//import Button from '@material-ui/core/Button';
//import Link from '@material-ui/core/Link';
import AccessTime from "@material-ui/icons/AccessTime";
import Payment from "@material-ui/icons/Payment";
import Person from "@material-ui/icons/Person";
import AttachMoney from "@material-ui/icons/AttachMoney";
import School from "@material-ui/icons/School";
import Language from "@material-ui/icons/Language";
import FastForward from "@material-ui/icons/FastForward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import { selectEntityIdentifiers } from "../partners/selectors";
import {
  StatCard,
  TableGraph,
  StyledGridContainer,
  StyledGridItem,
  VaultCardHeaderIcon
} from "components";
import Grid from "../components/grids/Grid";
import DownloadButton from "../components/buttons/DashboardDownload";
import { requestGetImpactReports } from "./actions/reportActions";
import stateName from "../helpers/stateName";
import Table from "packages/mui-dashboard/components/Table/Table.jsx";
import Card from "packages/mui-dashboard/components/Card/Card.jsx";
import CardBody from "packages/mui-dashboard/components/Card/CardBody.jsx";

const HintDiv = styled.div`
  background: #f9e7bb;
  border-radius: 3px;
  border: 1px solid #edaf00;
  padding: 5px;
  color: #333;
  font-size: 10px;
  position: absolute;
  margin: -15px 0 0 -80px;
  width: 40px

  &::after {
    border-radius: 5px;
    border: 2px solid #a10066
    background: #a10066
    display: block;
    content: " ";
    height: 6px;
    width: 6px;
    top: 8px;
    left: 74px;
    position: absolute;
  }
`;

export class EmployerDashboard extends Component {
  componentDidMount() {
    const { employerID, fetchRegisteredClick, fetchEmployersDetails } = this.props;
    fetchRegisteredClick(employerID);
    fetchEmployersDetails(employerID)
  }

  state = {
    hoveredCell: false,
    showClicks: false
  };

  formatCurrency = (val, defaultValue) => {
    return isFinite(toNumber(val)) ? formatCurrency(val, true) : defaultValue;
  };

  filterEnrollmentData = enrollmentData => {
    return reject(
      enrollmentData,
      val => val.x === "Head Count" || !isFinite(val.y)
    );
  };

  filterAccountGoalData = accountGoalData => {
    return filter(accountGoalData, val => isFinite(val.y));
  };

  shouldDrawData = d => isFinite(d.y);

  calcActiveAccountsGoal = totalHeadCount => round(totalHeadCount * 0.05) || 0;

  calcPercentage = (numA, numB) => {
    if (!numB) {
      return "0";
    }

    const rounded = round((numA * 100) / numB);
    return !numA ? 0 : `${rounded}${rounded ? "%" : ""}`;
  };

  avgPayoffTime = numMonths => {
    if (!numMonths || numMonths === 0) {
      return "-";
    }
    let floatYears;
    //parseFloat(119/12).toPrecision(2) => 9.9 => correct
    //parseFloat(121/12).toPrecision(2) => 10 => incorrect
    //parseFloat(121/12).toPrecision(3) => 10.1 => correct
    if (numMonths < 120) {
      floatYears = parseFloat(numMonths / 12).toPrecision(2);
    } else {
      floatYears = parseFloat(numMonths / 12).toPrecision(3);
    }
    //If there is no fraction of a year, do not display the decimal
    //For example, display '10 years' and not '10.0' years
    if (numMonths % 12 === 0) {
      const intYears = parseInt(floatYears);
      if (intYears === 1) {
        return `${intYears} year`;
      }
      return `${intYears} years`;
    }
    //value contains fraction of a year
    return `${floatYears} years`;
  };

  render() {
    const { hoveredCell } = this.state;
    const {
      advisorStats,
      benefitStats,
      totalEmployees,
      payEnabled,
      isLoading,
      employer,
      requestGetImpactReports,
      employerID,
      entityIdentifiers,
      registeredClicksDataLoading,
      registeredClicksData,
      employersDetails
    } = this.props;
    const showFiveTwoNineReport = employersDetails && employersDetails.employersData && employersDetails.employersData.data.attributes.has_five_two_nine; 
    const advisorStatsRefinancingVisitCount = advisorStats.refinancing_visit_count
      ? advisorStats.refinancing_visit_count
      : 0;
    const advisorStatsLowerPaymentsVisitCount = advisorStats.lower_payments_visit_count
      ? advisorStats.lower_payments_visit_count
      : 0;
    const advisorStatsTotalEmployeesCount = advisorStats.total_employees_count
      ? advisorStats.total_employees_count
      : 0;
    // totalEmployees is the employer's arbitrarily-set total_employees
    const totalHeadCount = toNumber(
      totalEmployees || advisorStatsTotalEmployeesCount
    );

    const advisorStatsPayFasterVisitCount = advisorStats.pay_faster_visit_count
      ? advisorStats.pay_faster_visit_count
      : 0;
    const activeEmployees = advisorStats.active_employees_count
      ? advisorStats.active_employees_count
      : 0;
    const eligibleEmployees = advisorStats.eligible_employees_count
      ? advisorStats.eligible_employees_count
      : 0;
    const enrolledEmployees = advisorStats.enrolled_employees_count
      ? advisorStats.enrolled_employees_count
      : 0;
    const allFamilyCount = advisorStats.all_family_count
      ? advisorStats.all_family_count
      : 0;
    const advisorStatsTotalBalance = advisorStats.total_balance
      ? advisorStats.total_balance
      : 0;
    const advisorStatsAvgBalance = advisorStats.avg_balance
      ? advisorStats.avg_balance
      : 0;
    const advisorStatsAvgMonthlyPayment = advisorStats.avg_monthly_payment
      ? advisorStats.avg_monthly_payment
      : 0;
    const advisorStatsAvgPayoffTime = advisorStats.avg_payoff_time
      ? advisorStats.avg_payoff_time
      : 0;

    const totalDebt = this.formatCurrency(advisorStatsTotalBalance, "-");
    const avgDebt = this.formatCurrency(advisorStatsAvgBalance, "-");
    const avgPayment = this.formatCurrency(advisorStatsAvgMonthlyPayment, "-");

    const avgPayoffTime = this.avgPayoffTime(advisorStatsAvgPayoffTime);

    const benefitStatsAvgSavingsWithBenefit = benefitStats.avg_savings_with_benefit
      ? benefitStats.avg_savings_with_benefit
      : 0;
    const avgSavingsPay = this.formatCurrency(
      benefitStatsAvgSavingsWithBenefit,
      "-"
    );
    const benefitStatsAvgTimeSavedWithBenefit = benefitStats.avg_time_saved_with_benefit
      ? benefitStats.avg_time_saved_with_benefit
      : 0;
    const avgTimeSavedPay = `${round(
      benefitStatsAvgTimeSavedWithBenefit || 0
    )} months`;
    const benefitStatsDistributedFunds = benefitStats.distributed_funds
      ? benefitStats.distributed_funds
      : 0;
    const totalCorpContr = this.formatCurrency(
      benefitStatsDistributedFunds,
      "-"
    );
    const activeAccountsGoal = this.calcActiveAccountsGoal(
      totalHeadCount,
      enrolledEmployees
    );

    const enrollmentData = [
      { x: "Head Count", y: totalHeadCount },
      {
        x: `Active ${entityIdentifiers.employeeIdentifier}s`,
        y: eligibleEmployees
      },
      {
        x: `Enrolled ${entityIdentifiers.employeeIdentifier}s`,
        y: enrolledEmployees || 0,
        z: this.calcPercentage(enrolledEmployees, totalHeadCount)
      },
      {
        x: `Debt Holding ${entityIdentifiers.employeeIdentifier}s`,
        y: activeEmployees,
        z: this.calcPercentage(activeEmployees, totalHeadCount)
      }
    ];

    if (allFamilyCount > 0) {
      enrollmentData.push({ x: "Invited Family Members", y: allFamilyCount });
    }

    const vaultPayData = [
      {
        x: `Debt Holding ${entityIdentifiers.employeeIdentifier}s in Pay`,
        y: benefitStats.pay_enrolled_employees_count
      },
      {
        x: `Average ${entityIdentifiers.employeeIdentifier} Pay Savings`,
        y: avgSavingsPay
      },
      {
        x: `Average ${entityIdentifiers.employeeIdentifier} Pay Time Saved (mos.)`,
        y: avgTimeSavedPay
      },
      { x: "Total Contributions Distributed", y: totalCorpContr }
    ];

    const fiveTwoNinePlaceholder = [
      {
        x: "Contributees",
        y: "0"
      },
      {
        x: "Contributors",
        y: "0"
      },
      {
        x: "Average Contribution",
        y: "$0"
      },
      {
        x: "529 users",
        y: "0"
      },
      {
        x: "Number of user clicks on the state map",
        y: "0"
      }
    ];

    const regClickDataToArray = Object.entries(
      registeredClicksData !== null && registeredClicksData.clickStats
    );

    const registeredClickData = [
      regClickDataToArray
        .map(e => {
          if (e[0] === "average_contribution") {
            return {
              x: stateName[e[0]],
              y: "$" + e[1]
            };
          } else if (e[1] === null) {
            return {
              x: stateName[e[0]],
              y: "0"
            };
          } else {
            return {
              x: stateName[e[0]],
              y: e[1]
            };
          }
        })
        .filter(e => {
          return e.x !== "Date";
        })
    ];

    const stateData = registeredClickData[0].filter(e => {
      return e.x && e.x.includes(":");
    });
    const clicksData = registeredClickData[0].filter(e => {
      return e.x && !e.x.includes(":");
    });

    // divide array into four parts to map it seperately
    const quarterIndex = Math.ceil(stateData.length / 4);
    const stateColOne = stateData.splice(0, quarterIndex);
    const stateColTwo = stateData.splice(0, quarterIndex);
    const stateColThree = stateData.splice(0, quarterIndex);
    const stateColFour = stateData.splice(0, quarterIndex, -quarterIndex);
    const regClicksData = [
      [...stateColOne],
      [...stateColTwo],
      [...stateColThree],
      [...stateColFour]
    ];

    const impactReportFileName = `${
      employer ? employer.name : ""
    }_ImpactReport_${moment().format("MM/DD/YYYY")}`;

    return (
      <React.Fragment>
        <StyledGridContainer>
          <StyledGridItem xs={12}>
            <Grid container justify="flex-end">
              <Grid item>
                {/*<Link href={`#/employers/${employerID}/match_reports`}>
                  Link
                </Link>*/}
                <DownloadButton
                  onClick={() =>
                    requestGetImpactReports(employerID, impactReportFileName)
                  }
                >
                  Impact Report
                </DownloadButton>
              </Grid>
            </Grid>
          </StyledGridItem>
        </StyledGridContainer>
        <StyledGridContainer>
          <StyledGridItem xs={12} sm={6} md={6} lg={3}>
            <StatCard
              title={`Total ${entityIdentifiers.employeeIdentifier} Debt Balance`}
              data={totalDebt}
              icon={<AttachMoney />}
              footer="Advisor"
              isLoading={isLoading}
              color="info"
            />
          </StyledGridItem>
          <StyledGridItem xs={12} sm={6} md={6} lg={3}>
            <StatCard
              title={`Average ${entityIdentifiers.employeeIdentifier} Debt Balance`}
              data={avgDebt}
              icon={<Person />}
              footer="National Total Debt Average: $29,650"
              isLoading={isLoading}
              color="rose"
            />
          </StyledGridItem>
          <StyledGridItem xs={12} sm={6} md={6} lg={3}>
            <StatCard
              title={`Average ${entityIdentifiers.employeeIdentifier} Monthly Payment`}
              data={avgPayment}
              icon={<Payment />}
              footer="National Monthly Payment Average: $393"
              isLoading={isLoading}
              color="success"
            />
          </StyledGridItem>
          <StyledGridItem xs={12} sm={6} md={6} lg={3}>
            <StatCard
              title={`Average ${entityIdentifiers.employeeIdentifier} Payoff`}
              data={avgPayoffTime}
              icon={<AccessTime />}
              footer="Advisor"
              isLoading={isLoading}
              color="warning"
            />
          </StyledGridItem>
        </StyledGridContainer>
        <StyledGridContainer>
          <StyledGridItem xs={12}>
            <TableGraph
              title="Enrollment"
              isLoading={isLoading}
              icon={<School />}
              tableData={enrollmentData.map(val => [val.x, val.y, val.z])}
              graph={
                <FlexibleXYPlot
                  xType="ordinal"
                  fill="#ff7843"
                  stroke="#ff7843"
                  stackBy="y"
                  yDomain={[0, totalHeadCount]}
                  // margin={{ bottom: 65 }}
                >
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <XAxis
                    title={totalHeadCount === 0 ? "No Data" : ""}
                    // tickLabelAngle={-15}
                  />
                  <YAxis
                    title={`Total ${entityIdentifiers.employeeIdentifier}s`}
                    hideTicks={totalHeadCount === 0}
                  />
                  <VerticalBarSeries
                    data={this.filterEnrollmentData(enrollmentData)}
                    getNull={this.shouldDrawData}
                    barWidth={0.25}
                    onValueMouseOver={v => {
                      this.setState({ hoveredCell: v });
                    }}
                    onValueMouseOut={() =>
                      this.setState({ hoveredCell: false })
                    }
                  />
                  {activeAccountsGoal ? (
                    <Hint
                      value={{
                        x: `Enrolled ${entityIdentifiers.employeeIdentifier}s`,
                        y: activeAccountsGoal
                      }}
                    >
                      <HintDiv>{`Target: 5% (${activeAccountsGoal})`}</HintDiv>
                    </Hint>
                  ) : (
                    <React.Fragment />
                  )}

                  {hoveredCell && (
                    <Hint
                      value={hoveredCell}
                      align={{
                        horizontal: Hint.ALIGN.AUTO,
                        vertical: Hint.ALIGN.BOTTOM
                      }}
                    >
                      <div className="rv-hint__content">{hoveredCell.y}</div>
                    </Hint>
                  )}
                </FlexibleXYPlot>
              }
            />
          </StyledGridItem>
        </StyledGridContainer>
        <StyledGridContainer>
          <StyledGridItem xs={12} sm={6} md={6} lg={3}>
            <StatCard
              title={`Visited Lower Payment`}
              data={advisorStatsLowerPaymentsVisitCount || "-"}
              icon={<ArrowDownwardIcon />}
              footer={`Advisor ${this.calcPercentage(
                advisorStatsLowerPaymentsVisitCount,
                activeEmployees
              )}`}
              isLoading={isLoading}
              color="success"
            />
          </StyledGridItem>
          <StyledGridItem xs={12} sm={6} md={6} lg={3}>
            <StatCard
              title={`Visited Refinance`}
              data={advisorStatsRefinancingVisitCount || "-"}
              icon={<AttachMoney />}
              footer={`Advisor ${this.calcPercentage(
                advisorStatsRefinancingVisitCount,
                activeEmployees
              )}`}
              isLoading={isLoading}
              color="success"
            />
          </StyledGridItem>
          <StyledGridItem xs={12} sm={6} md={6} lg={3}>
            <StatCard
              title={`Visited Pay Faster`}
              data={advisorStatsPayFasterVisitCount || "-"}
              icon={<FastForward />}
              footer={`Advisor ${this.calcPercentage(
                advisorStatsPayFasterVisitCount,
                activeEmployees
              )}`}
              isLoading={isLoading}
              color="success"
            />
          </StyledGridItem>
        </StyledGridContainer>
        {payEnabled && (
          <StyledGridContainer>
            <StyledGridItem xs={12}>
              <TableGraph
                title="Vault Pay"
                icon={<Language />}
                tableData={vaultPayData.map(val => [val.x, val.y])}
                isLoading={isLoading}
              />
            </StyledGridItem>
          </StyledGridContainer>
        )}

        {showFiveTwoNineReport === true && (
          <StyledGridContainer>
            <StyledGridItem xs={12}>
              <Card>
                <VaultCardHeaderIcon
                  icon={<Language />}
                  title="529"
                  color="info"
                />

                <CardBody>
                  <StyledGridItem xs>
                    {clicksData.length <= 1 ?
                      <Table
                        tableData={fiveTwoNinePlaceholder.map(val => [val.x, val.y])}
                      />
                      :
                      <>
                        <Table
                          tableData={clicksData
                            .filter(val => !val.x.startsWith("Number of"))
                            .map(val => [val.x, val.y])}
                        />
                        <div style={{ cursor: "pointer" }}>
                          <Table
                            clickHandler={() =>
                              this.setState(prevState => ({
                                showClicks: !prevState.showClicks
                              }))
                            }
                            tableData={clicksData
                              .filter(val => val.x.startsWith("Number of"))
                              .map(val => [val.x, val.y])}
                          />
                        </div>
                      </>
                    }
                  </StyledGridItem>
                  <br></br>
                  {this.state.showClicks ? (
                    <StyledGridContainer justify="space-between">
                      {regClicksData.map((e, i) => {
                        return (
                          <StyledGridItem key={i} xs>
                            <Table tableData={e.map(val => [val.x, val.y])} />
                          </StyledGridItem>
                        );
                      })}
                    </StyledGridContainer>
                  ) : (
                    ""
                  )}
                </CardBody>
              </Card>
            </StyledGridItem>
          </StyledGridContainer>
        )}
      </React.Fragment>
    );
  }
}

EmployerDashboard.propTypes = {
  advisorStats: PropTypes.shape({
    avg_balance: PropTypes.number,
    avg_balance_for_active_employees_in_system: PropTypes.number,
    total_balance: PropTypes.number,
    avg_monthly_payment: PropTypes.number,
    avg_monthly_payment_for_active_employees_in_system: PropTypes.number,
    lower_payments_visit_count: PropTypes.number,
    pay_faster_visit_count: PropTypes.number,
    refinancing_visit_count: PropTypes.number,
    active_employees_count: PropTypes.number,
    enrolled_employees_count: PropTypes.number,
    eligible_employees_count: PropTypes.number,
    all_family_count: PropTypes.number,
    total_employees_count: PropTypes.number
  }),
  benefitStats: PropTypes.shape({
    avg_savings_with_benefit: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    avg_time_saved_with_benefit: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    distributed_funds: PropTypes.number
  }),
  payEnabled: PropTypes.bool,
  totalEmployees: PropTypes.number,
  isLoading: PropTypes.bool.isRequired
};

EmployerDashboard.defaultProps = {
  advisorStats: {},
  benefitStats: {},
  payEnabled: false,
  totalEmployees: 0
};

function mapStateToProps(state) {
  const entityIdentifiers = selectEntityIdentifiers(state);
  return {
    entityIdentifiers,
    registeredClicksDataLoading: state.registeredClicksStats.isFetching,
    registeredClicksData: state.registeredClicksStats.data,
    employersDetails: state.employersDetails.data,
  };
}

const enhance = compose(
  muiThemeable(),
  connect(
    mapStateToProps,
    {
      requestGetImpactReports,
      fetchRegisteredClick: fetchRegisteredClickAction,
      fetchEmployersDetails: fetchEmployersDetailsAction
    }
  )
);

export default enhance(EmployerDashboard);
