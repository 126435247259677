import { put, takeEvery, all, call, takeLatest } from "redux-saga/effects";
import { assign } from "lodash";
import { destroy } from "redux-form";
import { jsonApiHttpClient } from "../rest/fetch";

import log from "../services/logging/service";
import {
  DOWNLOAD_PARTNER_REPORT_SUCCESS,
  DOWNLOAD_PARTNER_REPORT_FAILURE,
  FETCH_PARTNER_STATS_SUCCESS,
  FETCH_PARTNER_STATS_FAILURE,
  FETCH_PARTNER_STATS,
  FETCH_PARTNER,
  FETCH_PARTNER_SUCCESS,
  FETCH_PARTNER_FAILURE,
  FETCH_PARTNERS,
  FETCH_PARTNERS_SUCCESS,
  FETCH_PARTNERS_FAILURE,
  CREATE_PARTNER_SUCCESS,
  CREATE_PARTNER_FAILURE,
  CREATE_PARTNER,
  EDIT_PARTNER_SUCCESS,
  EDIT_PARTNER_FAILURE,
  EDIT_PARTNER,
  TOGGLE_PARTNER_REFI_VENDOR_SUCCESS,
  TOGGLE_PARTNER_REFI_VENDOR_FAILURE,
  TOGGLE_PARTNER_REFI_VENDOR,
  FETCH_PARTNER_REG_CLICK,
  FETCH_PARTNER_REG_CLICK_SUCCESS,
  FETCH_PARTNER_REG_CLICK_FAILURE,
  FETCH_PARTNERS_DETAILS,
  FETCH_PARTNERS_DETAILS_SUCCESS,
  FETCH_PARTNERS_DETAILS_FAILURE,
} from "./partnerActions";
import { showNotification } from "../app/uiActions";
import { FETCH_ERROR } from "../auth/authActions";
import { closeAllModals } from "../app/uiActions";
import slgRestClient from "../rest/slgRestClient";
import { GET_LIST } from "../rest/types";
import { CREATE, UPDATE } from "../rest/types";
import {
  getAllRelationships,
  parseJSONResp
} from "../helpers/restClientHelpers";

function _submissionErrorNotification(errorMessage) {
  return showNotification(
    errorMessage + ", please update and resubmit.",
    "warning"
  );
}

function* downloadPartnerReportSuccess() {
  yield put(showNotification("Report successfully downloaded!"));
}

function* downloadPartnerReportFailure({ error }) {
  yield put(
    showNotification(
      "An error occurred while downloading your report",
      "warning"
    )
  );
  console.error(error);
}

function requestPartnerStats(partnerID) {
  return jsonApiHttpClient(
    `${process.env.REACT_APP_API_HOST}/partners/${partnerID}/employer_statistics`
  )
    .then(resp => {
      //({ ...resp.json.data, partnerStatsLoaded: true }))
      //return { ...resp.json.data, partnerStatsLoaded: true };
    
      if(resp.json.data.length >0){
        return { ...resp.json.data[0]['attributes'], partnerStatsLoaded: true };
      }else{
        let res = {};
        return { ...res, partnerStatsLoaded: true };
      }
    })
    .catch(e => log.error(e.message));
}

function* fetchPartnerStats(action) {
  try {
    const partnerStats = yield call(
      requestPartnerStats,
      action.payload.partnerID
    );
    yield put({
      type: FETCH_PARTNER_STATS_SUCCESS,
      payload: { stats: partnerStats, partnerID: action.payload.partnerID }
    });
  } catch (e) {
    yield put({ type: FETCH_PARTNER_STATS_FAILURE, message: e.message });
    yield put({ type: FETCH_ERROR, e });
    yield put(
      showNotification(
        "An error occurred while fetching partner stats",
        "warning"
      )
    );
    log.error(e.message);
  }
}

function requestPartner(partnerID) {
  return jsonApiHttpClient(
    `${process.env.REACT_APP_API_HOST}/partners/${partnerID}`
  )
    .then(resp => _parsePartnerResp(resp.json))
    .catch(e => log.error(e.message));
}

function* fetchPartner(action) {
  try {
    const partner = yield call(requestPartner, action.payload.partnerID);
    yield put({ type: FETCH_PARTNER_SUCCESS, payload: partner });
  } catch (e) {
    yield put({ type: FETCH_PARTNER_FAILURE, message: e.message });
    yield put({ type: FETCH_ERROR, e });
    yield put(
      showNotification("An error occurred while fetching partner", "warning")
    );
    log.error(e.message);
  }
}

function requestPartnerCreate(data) {
  return slgRestClient(CREATE, "partners", { data })
    .then(resp => resp.data)
    .catch(e => log.error(e.message));
}

function* createPartner(action) {
  try {
    const partnerCreate = yield call(requestPartnerCreate, action.payload.data);
    yield put({ type: CREATE_PARTNER_SUCCESS, payload: partnerCreate });
    yield put(destroy(action.formName));
    yield put(closeAllModals());
    yield put(showNotification("Partner created successfully"));
  } catch (e) {
    yield put({ type: CREATE_PARTNER_FAILURE, message: e.message });
    yield put(_submissionErrorNotification(e.message));
    log.error(e.message);
  }
}

function requestPartnerEdit(data) {
  return slgRestClient(UPDATE, "partners", assign({ id: data.id }, { data }))
    .then(resp => _parsePartnerResp(resp))
    .catch(e => log.error(e.message));
}

function* editPartner(action) {
  try {
    const partnerEdit = yield call(requestPartnerEdit, action.payload.data);
    yield put({ type: EDIT_PARTNER_SUCCESS, payload: partnerEdit });
    yield put(destroy(action.formName));
    yield put(closeAllModals());
    yield put(showNotification("Partner edited successfully"));
  } catch (e) {
    yield put({ type: EDIT_PARTNER_FAILURE, message: e.message });
    yield put(_submissionErrorNotification(e.message));
    log.error(e.message);
  }
}

function requestPartnerToggleRefiVendor(data) {
  var requestHeaders = new Headers({
    Accept: "application/vnd.slg+json",
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("token")
  });

  const updateAttributes = data.toggled
    ? { refi_vendor_id: data.refiVendorID }
    : { id: data.partnerRefiVendorID, _destroy: true };

  const updateParams = {
    data: {
      type: "partners",
      attributes: {
        refi_vendors_vendorables_attributes: [updateAttributes]
      }
    }
  };

  return fetch(
    `${process.env.REACT_APP_API_HOST}/partners/${data.partnerID}/toggle_refi_vendor`,
    {
      method: "PUT",
      headers: requestHeaders,
      body: JSON.stringify(updateParams)
    }
  )
    .then(response => response.text())
    .then(text => JSON.parse(text))
    .then(json => parseJSONResp(json))
    .catch(e => log.error(e.message));
}

function* togglePartnerRefiVendor(action) {
  try {
    const toggleRefiVendor = yield call(
      requestPartnerToggleRefiVendor,
      action.payload
    );
    yield put({
      type: TOGGLE_PARTNER_REFI_VENDOR_SUCCESS,
      payload: toggleRefiVendor
    });
  } catch (e) {
    yield put({ type: TOGGLE_PARTNER_REFI_VENDOR_FAILURE, message: e.message });
    yield put(_submissionErrorNotification(e.message));
    log.error(e.message);
  }
}

function requestPartners() {
  const params = {
    filter: [],
    // No pagination implemented. If partners ever need pagination, rethink component presentation
    pagination: { page: 1, perPage: 25 },
    sort: { field: "name", order: "ASC" }
  };

  return slgRestClient(GET_LIST, "partners", params)
    .then(resp => resp)
    .catch(e => log.error(e.message));
}

function* fetchPartners(action) {
  try {
    const partners = yield call(requestPartners);
    yield put({
      type: FETCH_PARTNERS_SUCCESS,
      payload: { partners, defaultPartnerID: action.payload.defaultPartnerID }
    });
  } catch (e) {
    yield put({ type: FETCH_PARTNERS_FAILURE, message: e.message });
    yield put({ type: FETCH_ERROR, e });
    yield put(
      showNotification("An error occurred while fetching employers", "warning")
    );
    log.error(e.message);
  }
}

function _parsePartnerResp(resp) {
  return {
    id: resp.data.id,
    ...resp.data.attributes,
    ...getAllRelationships(resp)
  };
}

function requestPartnerRegClick(partnerID) {
  var requestHeaders = new Headers({
    Accept: "application/vnd.slg+json",
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("token"),
  });

  return fetch(
    `${process.env.REACT_APP_API_HOST}/partners/${partnerID}/five_two_nine_stats`,
    {
      method: "GET",
      headers: requestHeaders,
    }
  )
    .then((response) => {
      return response.text();
    })

    .then((text) => {
      let json = JSON.parse(text);

      if (json["data"].length > 0) {
        return { ...json["data"][0]["attributes"] };
      } else {
        let resp = {};
        return { ...resp, advisorStatsLoaded: true };
      }
    });
}

function* fetchPartnerRegClicksStats(action) {
  try {
    const partnerID = action.payload.partnerID;
    const clickStats = yield call(
      requestPartnerRegClick,
      partnerID
    );

    yield put({
      type: FETCH_PARTNER_REG_CLICK_SUCCESS,
      payload: { clickStats },
    });
  } catch (e) {
    yield put({ type: FETCH_PARTNER_REG_CLICK_FAILURE, message: e.message });
    yield put(
      showNotification(
        "An error occurred while fetching 529 stats",
        "warning"
      )
    );
    console.log(e);
  }
}


function requestPartnersDetails(partnerID) {
  var requestHeaders = new Headers({
    Accept: "application/vnd.slg+json",
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("token"),
  });

  return fetch(`${process.env.REACT_APP_API_HOST}/partners/${partnerID}/`, {
    method: "GET",
    headers: requestHeaders,
  })
    .then((response) => {
    
      return response.text();
    })
    .then((text) => {
      let json = JSON.parse(text);
      if (json["data"]) {
        return ( json ) ;
      } else {
        let resp = {};
        return { ...resp, advisorStatsLoaded: true };
      }
    });
}

function* fetchPartnersDetails(action) {
  try {
    const partnerID = action.payload.partnerID;
    const partnersData = yield call(requestPartnersDetails, partnerID);
    yield put({
      type: FETCH_PARTNERS_DETAILS_SUCCESS,
      payload: { partnersData },
    });
  } catch (e) {
    yield put({ type: FETCH_PARTNERS_DETAILS_FAILURE, message: e.message });
    yield put(
      showNotification(
        "An error occurred while fetching employer details",
        "warning"
      )
    );
    console.log(e);
  }
}


export default function* partnerSaga() {
  yield all([
    takeEvery(DOWNLOAD_PARTNER_REPORT_SUCCESS, downloadPartnerReportSuccess),
    takeEvery(DOWNLOAD_PARTNER_REPORT_FAILURE, downloadPartnerReportFailure),
    takeLatest(FETCH_PARTNER_STATS, fetchPartnerStats),
    takeLatest(FETCH_PARTNER, fetchPartner),
    takeLatest(FETCH_PARTNERS, fetchPartners),
    takeLatest(CREATE_PARTNER, createPartner),
    takeLatest(EDIT_PARTNER, editPartner),
    takeLatest(TOGGLE_PARTNER_REFI_VENDOR, togglePartnerRefiVendor),
    takeLatest(FETCH_PARTNER_REG_CLICK, fetchPartnerRegClicksStats),
    takeLatest(FETCH_PARTNERS_DETAILS, fetchPartnersDetails),
  ]);
}
