import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import reduce from "lodash/reduce";
import round from "lodash/round";
import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  Hint,
} from "react-vis";
import Payment from "@material-ui/icons/Payment";
import DateRange from "@material-ui/icons/DateRange";
import Language from "@material-ui/icons/Language";
import People from "@material-ui/icons/People";
import Person from "@material-ui/icons/Person";
import AttachMoney from "@material-ui/icons/AttachMoney";
import { fetchPartnerRegClick as fetchPartnerRegClickAction, fetchPartnersDetails as fetchPartnersDetailsAction } from "../partners/partnerActions";

import { formatCurrency } from "helpers/formattingHelpers";
import {
  StatCard,
  TableGraph,
  StyledGridContainer,
  StyledGridItem,
  VaultCardHeaderIcon
} from "components";
import { selectEntityIdentifiers } from "../partners/selectors";
import stateName from "../helpers/stateName";
import Table from "packages/mui-dashboard/components/Table/Table.jsx";
import Card from "packages/mui-dashboard/components/Card/Card.jsx";
import CardBody from "packages/mui-dashboard/components/Card/CardBody.jsx";

export class Stats extends Component {
  state = {
    hoveredCell: false,
  };

  componentDidMount() {
    const { partnerID, fetchPartnerRegClick, fetchPartnersDetails } = this.props;
    fetchPartnerRegClick(partnerID);
    fetchPartnersDetails(partnerID);
  }

  filterEnrollmentData = (enrollmentData) => {
    const { entityIdentifiers } = this.props;

    const barGraphShortNames = {
      [`Total ${entityIdentifiers.employeeIdentifier}s`]: "Total",
      [`Active ${entityIdentifiers.employeeIdentifier}s`]: "Active",
      [`Debt Holding ${entityIdentifiers.employeeIdentifier}s`]: "Debt Holding",
      [`Active ${entityIdentifiers.employeeIdentifier}s in Pay`]: "In Pay",
    };

    return reduce(
      enrollmentData,
      (result, obj) => {
        if (!obj.barGraph || !obj.y) {
          return result;
        }

        result.push({ x: barGraphShortNames[obj.x], y: obj.y });
        return result;
      },
      []
    );
  };

  render() {
    const { isLoading, partnerStats, entityIdentifiers, registeredClicksData, partnersDetails } = this.props;
    const { hoveredCell } = this.state;
    const {
      pay_benefit: benefitStats,
      advisor: advisorStats,
      employers,
    } = partnerStats;
    const showFiveTwoNineReport = partnersDetails && partnersDetails.partnersData && partnersDetails.partnersData.data && partnersDetails.partnersData.data.attributes.has_five_two_nine;

    const totalEmployees =
      employers && employers.data
        ? employers.data.attributes.all_employees_count
        : 0;
    const allFamilyCount =
      employers && employers.data
        ? employers.data.attributes.all_family_count
        : 0;
    const employersCount =
      employers && employers.data
        ? employers.data.attributes.employers_count
        : 0;
    const eligibleEmployeesCount =
      employers && employers.data
        ? employers.data.attributes.eligible_employees_count
        : 0;
    const enrolledEmployeesCount =
      employers && employers.data
        ? employers.data.attributes.enrolled_employees_count
        : 0;
    const activeEmployeesCount =
      employers && employers.data
        ? employers.data.attributes.active_employees_count
        : 0;
    const employeesOnLastPdr =
      employers && employers.data
        ? employers.data.attributes.employees_on_last_pdr
        : 0;
    const totalLoanCount =
      advisorStats && advisorStats.data
        ? advisorStats.data.attributes.total_loan_count
        : 0;
    const payEmployersCount =
      employers && employers.data
        ? employers.data.attributes.pay_employers_count
        : 0;
    const avgSavingsWithBenefit =
      benefitStats && benefitStats.data
        ? benefitStats.data.attributes.avg_savings_with_benefit
        : 0;
    const avgTimeSavedWithBenefit =
      benefitStats && benefitStats.data
        ? benefitStats.data.attributes.avg_time_saved_with_benefit
        : 0;
    const distributedFunds =
      employers && employers.data
        ? employers.data.attributes.distributed_funds
        : 0;
    const totalBalance =
      advisorStats && advisorStats.data
        ? advisorStats.data.attributes.total_balance
        : 0;
    const avgBalance =
      advisorStats && advisorStats.data
        ? advisorStats.data.attributes.avg_balance
        : 0;
    const avgMonthlyPayment =
      advisorStats && advisorStats.data
        ? advisorStats.data.attributes.avg_monthly_payment
        : 0;
    const avgPayoffTime =
      advisorStats && advisorStats.data
        ? advisorStats.data.attributes.avg_payoff_time
        : 0;

    const enrollmentData = [
      {
        x: `Total ${entityIdentifiers.employerIdentifier}s`,
        y: employersCount || 0,
        barGraph: false,
      },
      {
        x: `Total ${entityIdentifiers.employeeIdentifier}s`,
        y: totalEmployees || 0,
        barGraph: true,
      },
      {
        x: `Active ${entityIdentifiers.employeeIdentifier}s`,
        y: eligibleEmployeesCount || 0,
        barGraph: true,
      },
      {
        x: `Enrolled ${entityIdentifiers.employeeIdentifier}s`,
        y: enrolledEmployeesCount || 0,
        barGraph: false,
      },
      {
        x: `Debt Holding ${entityIdentifiers.employeeIdentifier}s`,
        y: activeEmployeesCount || 0,
        barGraph: true,
      },
      {
        x: `Active ${entityIdentifiers.employeeIdentifier}s in Pay`,
        y: employeesOnLastPdr || 0,
        barGraph: true,
      },
      {
        x: "Total # of Loans",
        y: totalLoanCount || 0,
      },
    ];

    if (allFamilyCount > 0) {
      enrollmentData.splice(5, 0, {
        x: "Invited Family Members",
        y: allFamilyCount,
      });
    }

    const vaultPayData = [
      {
        x: `Active ${entityIdentifiers.employerIdentifier}s With Pay`,
        y: payEmployersCount || 0,
      },
      {
        x: `Average ${entityIdentifiers.employeeIdentifier} Pay Savings`,
        y: formatCurrency(avgSavingsWithBenefit, true),
      },
      {
        x: `Average ${entityIdentifiers.employeeIdentifier} Pay Time Saved (mos.)`,
        y: `${round(avgTimeSavedWithBenefit)} months`,
      },
      {
        x: "Total Contributions Distributed",
        y: formatCurrency(distributedFunds, true),
      },
    ];

    const fiveTwoNinePlaceholder = [
      {
        x: "Contributees",
        y: "0"
      },
      {
        x: "Contributors",
        y: "0"
      },
      {
        x: "Average Contribution",
        y: "$0"
      },
      {
        x: "529 users",
        y: "0"
      },
      {
        x: "Number of user clicks on the state map",
        y: "0"
      }
    ];

    const regClickDataToArray = Object.entries(
      registeredClicksData !== null && registeredClicksData.clickStats
    );

    const registeredClickData = [
      regClickDataToArray
        .map((e) => {
          if (e[0] === "average_contribution") {
            return {
              x: stateName[e[0]],
              y: "$" + e[1],
            };
          } else if (e[1] === null) {
            return {
              x: stateName[e[0]],
              y: "0",
            };
          } else {
            return {
              x: stateName[e[0]],
              y: e[1],
            };
          }
        })
        .filter((e) => {
          return e.x !== "Date";
        }),
    ];

    const stateData = registeredClickData[0].filter((e) => {
      return e.x && e.x.includes(":");
    });
    const clicksData = registeredClickData[0].filter((e) => {
      return e.x && !e.x.includes(":");
    });

    // divide array into four parts to map it seperately
    const quarterIndex = Math.ceil(stateData.length / 4);
    const stateColOne = stateData.splice(0, quarterIndex);
    const stateColTwo = stateData.splice(0, quarterIndex);
    const stateColThree = stateData.splice(0, quarterIndex);
    const stateColFour = stateData.splice(0, quarterIndex, -quarterIndex);
    const regClicksData = [
      [...stateColOne],
      [...stateColTwo],
      [...stateColThree],
      [...stateColFour],
    ];

    return (
      <React.Fragment>
        <StyledGridContainer>
          <StyledGridItem xs={12} sm={6} md={6} lg={3}>
            <StatCard
              title={`Total ${entityIdentifiers.employeeIdentifier} Debt Balance`}
              data={formatCurrency(totalBalance, true)}
              icon={<AttachMoney />}
              footer="Advisor"
              isLoading={isLoading}
              color="info"
            />
          </StyledGridItem>
          <StyledGridItem xs={12} sm={6} md={6} lg={3}>
            <StatCard
              title={`Average ${entityIdentifiers.employeeIdentifier} Debt Balance`}
              data={formatCurrency(avgBalance, true)}
              icon={<Person />}
              footer="Advisor"
              isLoading={isLoading}
              color="rose"
            />
          </StyledGridItem>
          <StyledGridItem xs={12} sm={6} md={6} lg={3}>
            <StatCard
              title={`Average ${entityIdentifiers.employeeIdentifier} Monthly Payment`}
              data={formatCurrency(avgMonthlyPayment, true)}
              icon={<Payment />}
              footer="Advisor"
              isLoading={isLoading}
              color="success"
            />
          </StyledGridItem>
          <StyledGridItem xs={12} sm={6} md={6} lg={3}>
            <StatCard
              title={`Average ${entityIdentifiers.employeeIdentifier} Payoff`}
              data={`${round(avgPayoffTime)} months`}
              icon={<DateRange />}
              footer="Advisor"
              isLoading={isLoading}
              color="warning"
            />
          </StyledGridItem>
        </StyledGridContainer>

        <StyledGridContainer>
          <StyledGridItem xs={12}>
            <TableGraph
              title="Enrollment"
              icon={<People />}
              isLoading={isLoading}
              tableData={enrollmentData.map((val) => [val.x, val.y])}
              graph={
                <FlexibleXYPlot
                  xType="ordinal"
                  fill="#ff7843"
                  stroke="#ff7843"
                  stackBy="y"
                  yDomain={[0, totalEmployees]}
                >
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <XAxis />
                  <YAxis
                    title={`Total ${entityIdentifiers.employeeIdentifier}s`}
                  />
                  <VerticalBarSeries
                    data={this.filterEnrollmentData(enrollmentData)}
                    barWidth={0.25}
                    onValueMouseOver={(v) => {
                      this.setState({ hoveredCell: v });
                    }}
                    onValueMouseOut={() =>
                      this.setState({ hoveredCell: false })
                    }
                  />
                  {hoveredCell && (
                    <Hint
                      value={hoveredCell}
                      align={{
                        horizontal: Hint.ALIGN.AUTO,
                        vertical: Hint.ALIGN.BOTTOM,
                      }}
                    >
                      <div className="rv-hint__content">{hoveredCell.y}</div>
                    </Hint>
                  )}
                </FlexibleXYPlot>
              }
            />
          </StyledGridItem>
        </StyledGridContainer>
        <StyledGridContainer>
          <StyledGridItem xs={12}>
            <TableGraph
              title="Pay"
              icon={<Language />}
              tableData={vaultPayData.map((val) => [val.x, val.y])}
              isLoading={isLoading}
            />
          </StyledGridItem>
        </StyledGridContainer>

        {showFiveTwoNineReport  === true && (
          <StyledGridContainer>
            <StyledGridItem xs={12}>
              <Card>
                <VaultCardHeaderIcon
                  icon={<Language />}
                  title="529"
                  color="info"
                />

                <CardBody>
                  <StyledGridItem xs>
                    {clicksData.length <= 1 ?
                      <Table
                        tableData={fiveTwoNinePlaceholder.map(val => [val.x, val.y])}
                      />
                      :
                      <>
                        <Table
                          tableData={clicksData
                            .filter((val) => !val.x.startsWith("Number of"))
                            .map((val) => [val.x, val.y])}
                        />
                        <div style={{ cursor: "pointer" }}>
                          <Table
                            clickHandler={() =>
                              this.setState((prevState) => ({
                                showClicks: !prevState.showClicks,
                              }))
                            }
                            tableData={clicksData
                              .filter((val) => val.x.startsWith("Number of"))
                              .map((val) => [val.x, val.y])}
                          />
                        </div>
                      </>
                    }
                  </StyledGridItem>
                  <br></br>
                  {this.state.showClicks ? (
                    <StyledGridContainer justify="space-between">
                      {regClicksData.map((e, i) => {
                        return (
                          <StyledGridItem key={i} xs>
                            <Table tableData={e.map((val) => [val.x, val.y])} />
                          </StyledGridItem>
                        );
                      })}
                    </StyledGridContainer>
                  ) : (
                    ""
                  )}
                </CardBody>
              </Card>
            </StyledGridItem>
          </StyledGridContainer>
        )}
      </React.Fragment>
    );
  }
}

Stats.propTypes = {
  partnerStats: PropTypes.shape({
    pay_benefit: PropTypes.object,
    advisor: PropTypes.object,
    employers: PropTypes.object,
  }),
  isLoading: PropTypes.bool.isRequired,
};

Stats.defaultProps = {
  partnerStats: {
    pay_benefit: {},
    advisor: {},
    employers: {},
  },
};

function mapStateToProps(state) {
  const entityIdentifiers = selectEntityIdentifiers(state);
  return {
    entityIdentifiers,
    registeredClicksDataLoading: state.partnerRegClicksStats.isFetching,
    registeredClicksData: state.partnerRegClicksStats.data,
    partnersDetails: state.partnersDetails.data,
  };
}

const enhance = compose(
  connect(mapStateToProps, {
    fetchPartnerRegClick: fetchPartnerRegClickAction,
    fetchPartnersDetails: fetchPartnersDetailsAction
  })
);
export default enhance(Stats);
