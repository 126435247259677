import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";

import muiThemeable from "material-ui/styles/muiThemeable";

import { fetchPartnerStats as fetchPartnerStatsAction } from "./partnerActions";
import { Stats } from "./";
import { selectStatsByPartnerID } from "./selectors";
import { makeSelectPartnerById } from "./selectors-ext/partnersSelectors";

export class PartnerDashboard extends Component {
  componentDidMount() {
    const partnerID = this.props.match.params.partnerID;
    const { fetchPartnerStats } = this.props;
    if (partnerID) {
      fetchPartnerStats(partnerID);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentPartnerID = this.props.match.params.partnerID;
    const nextPartnerID = nextProps.match.params.partnerID;
    if (currentPartnerID !== nextPartnerID) {
      this.props.fetchPartnerStats(nextPartnerID);
    }
  }

  render() {
    return (
      <div>
        <Stats
          isLoading={this.props.isLoading}
          partnerStats={this.props.partnerStats}
          muiTheme={this.props.muiTheme}
          partnerID={this.props.match.params.partnerID}
        />
      </div>
    );
  }
}

PartnerDashboard.propTypes = {
  fetchPartnerStats: PropTypes.func.isRequired,
  partnerStats: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  partner: PropTypes.object.isRequired
};

PartnerDashboard.defaultProps = {
  partnerStats: { employers: {}, pay_benefit: {}, advisor: {} }
};

function mapStateToProps(state, props) {
  const { partnerID } = props.match.params;
  return {
    partnerStats: selectStatsByPartnerID(state, partnerID),
    isLoading: state.partnerStats.isFetching,
    partner: makeSelectPartnerById(partnerID)(state)
  };
}

const enhance = compose(
  muiThemeable(),
  connect(
    mapStateToProps,
    {
      fetchPartnerStats: fetchPartnerStatsAction
    }
  )
);

export default enhance(PartnerDashboard);
