import React from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { openModal } from "../../app/uiActions";
import Link from "../../components/links/Link";


const PageAction = ({
  openModal,
  ...data
}) => {
  function downloadImage (e,url){
    var filename = url.substring(url.lastIndexOf('/')+1, url.lastIndexOf("."));
    
    fetch(url)
      .then(resp => resp.blob())
      .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          // the filename you want
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
      })
      .catch(() => alert('An error sorry'));
  }
  return (
    <Link to="#" onClick={(e)=> downloadImage(e,data.attributes.file_link) } >Download</Link>
  );
};

const mapDispatchToProps = (_dispatch, _ownProps) => {
  return { openModal, };
};

export default compose(
  connect(
    null,
    mapDispatchToProps()
  )
)(PageAction);